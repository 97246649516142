<template>
  <div class="footer" v-if="$route.meta.keepAlive">
    <div class="big_img">
      <img v-if="screenWidth >= 920" src="../assets/index/foo.png" alt="">
      <img v-else src="../assets/index/phone/foop.png" alt="">

    </div>
    <div class="foot border-margin">
      <div class="foot_content">
        <div class="left">
          <img src="../assets/logo.png" alt="">
          <div class="phone" v-if="screenWidth >= 920">
            <span>服务热线：</span>
            <br>
            <span>0592-5886986</span>
          </div>
          <div class="phone" v-else>
            <span>服务热线：0592-5886986</span>
          </div>
          <div class="address">
            地址：福建省厦门市嘉禾路588号盈趣科技大厦6楼
          </div>
        </div>
        <div class="right">
          <ul class="list">
            <li v-for="(item, index) in list1" :key="item.id">
              <router-link :to="item.link" :class="item.isfirst ? 'first' : ''">{{ item.name }}</router-link>
            </li>
          </ul>
          <ul class="list">
            <li v-for="(item, index) in list2" :key="item.id">
              <router-link :to="item.link" :class="item.isfirst ? 'first' : ''">{{ item.name }}</router-link>
            </li>
          </ul>
          <ul class="list">
            <li v-for="(item, index) in list3" :key="item.id">
              <router-link :to="item.link" :class="item.isfirst ? 'first' : ''">{{ item.name }}</router-link>
            </li>
          </ul>
          <ul class="list">
            <li v-for="(item, index) in list4" :key="item.id">
              <router-link :to="item.link" :class="{ 'first': item.isfirst }">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      list1: [
        { name: '首页', link: '/home', isfirst: true },
        { name: '业务内容', link: '/home?id=content', isfirst: false },
        { name: '业务分布', link: '/home?id=address', isfirst: false },
        { name: '服务标准', link: '/home?id=standard', isfirst: false },
        { name: '服务流程', link: '/home?id=process', isfirst: false },
        { name: '公司简介', link: '/home?id=desc', isfirst: false }
      ],
      list2: [
        { name: '人才招聘', link: '/recruit', isfirst: true },
        { name: '行政岗位', link: '/recruit?id=offi', isfirst: false },
        { name: '销售岗位', link: '/recruit?id=sale', isfirst: false }
      ],
      list3: [
        { name: '关于我们', link: '/about', isfirst: true },
        { name: '公司概况', link: '/about?id=desc', isfirst: false },
        { name: '集团创始人', link: '/about?id=people', isfirst: false },
        { name: '专业团队', link: '/about?id=group', isfirst: false }
      ],
      list4: [
        { name: '资讯中心', link: '/new', isfirst: true },
        { name: '公司资讯', link: '/new?id=company', isfirst: false },
        { name: '金融资讯', link: '/new?id=finance', isfirst: false }
      ]

    }
  },
  computed: {
    ...mapState(['businessHeight', 'screenWidth'])
  },
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
}

.big_img {
  width: 100%;

  img {
    width: 100%;
    display: block;
    background-size: cover;
  }
}

.foot {
  width: 100%;
  background-color: #232323;
}

.foot_content {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}

.left {
  text-align: left;
  color: #fff;

  img {
    width: 200px;
  }

  .phone {
    margin: 20px 0;

    span:first-of-type {
      font-size: 18px;
    }

    span:last-of-type {
      display: block;
      font-size: 24px;
      padding-top: 5px;
    }
  }
}

.right {
  display: flex;

  .list {
    padding-left: 30px;

    li {
      line-height: 35px;
    }

    a {
      display: block;
      color: #fff;
    }

    a.first {
      font-weight: 600;
      font-size: 20px;
    }
  }
}

@media screen and (max-width:980px) {
  .foot_content {
    width: 95%;
    flex-direction: column-reverse;

  }

  .left {
    text-align: center;
  }

  .right {
    justify-content: space-between;

    .list {
      padding-left: 0;
    }
  }
}
</style>