<template>
  <div class="home" ref="box">
    <div class="banner" v-if="screenWidth >= 920">
      <img src="../../assets/index/banner@2x.png" alt="">
      <div class="nums">
        <div class="item item1">
          <div class="title">
            <!-- <span>{{ toThousands(num1) }}</span>累计客户额 -->
            <span v-change="1000">4065863006</span>累计客户额
          </div>
          <div class="desc test-two-elli">
            获得平安银行、建设银行、中信银行授予最佳合作渠道，其中荣获平安银行放款第一渠道。
          </div>
        </div>
        <div class="item item2">
          <div class="title">
            <span v-change="500">32365</span>累计放款额
          </div>
          <div class="desc test-two-elli">
            福建省区域融资业务，个人和企业客户都是全省连续三年第一。
          </div>
        </div>
        <div class="item item3">
          <div class="title">
            <span v-change="10">33</span>个业务分布区
          </div>
          <div class="desc test-two-elli">
            华中、华南、华北、覆盖一线城市及全国90%的省会城市和二线城市
          </div>
        </div>
      </div>
    </div>
    <div class="banner_p" v-else>
      <img src="../../assets/index/phone/banner1@2x.png" alt="">
      <div class="nums">
        <div class="item item1">
          <div class="title">
            <span v-change="1000">4065863006</span>累计客户额
          </div>

        </div>
        <div class="item item2">
          <div class="title">
            <span v-change="1500">32365</span>累计放款额
          </div>

        </div>
        <div class="item item3">
          <div class="title">
            <span v-change="2000">33</span>个业务分布区
          </div>

        </div>
      </div>
    </div>
    <div class="business bPc" id="content" style="padding-top: 50px;" v-if="screenWidth >= 920">
      <el-carousel trigger="click" arrow="never" :autoplay="false" :height="businessHeight + 'px'">
        <el-carousel-item v-for="(item, index) in business" :key="index">
          <img style="width: 100%;height: inherit;" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="business bPhone" id="content" style="padding-top: 50px;" v-else>
      <el-carousel trigger="click" arrow="never" :autoplay="false" :height="businessHeight + 'px'">
        <el-carousel-item v-for="(item, index) in businessPhone" :key="index">
          <img style="width: 100%;height: inherit;" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="diqu" id="address">
      <img v-if="screenWidth >= 920" src="../../assets/index/earth.png" alt="">
      <img v-else src="../../assets/index/phone/earthp.png" alt="">

    </div>
    <div class="diqu">
      <img v-if="screenWidth >= 920" src="../../assets/index/cee.png" alt="">
      <img v-else src="../../assets/index/phone/ceep.png" alt="">
    </div>
    <div class="standard top" id="standard">
      <img v-if="screenWidth >= 920" src="../../assets/index/biaozhun.png" alt="">
      <img v-else src="../../assets/index/phone/bioazhunp.png" alt="">
    </div>
    <div class="standard top bottom" id="process">
      <img v-if="screenWidth >= 920" src="../../assets/index/server.png" alt="">
      <img v-else src="../../assets/index/phone/server.png" alt="">
    </div>
    <div class="diqu" id="desc">
      <img v-if="screenWidth >= 920" src="../../assets/index/jianjie.png" alt="">
      <img v-else src="../../assets/index/phone/jianjiep.png" alt="">
    </div>
    <div class="slider">
      <div class="diqu line">
        <!-- <img src="../../assets/index/Group 109@2x.png" alt=""> -->
        <div class="t">发展历程</div>
      </div>
      <div class="slide">
        <img v-if="screenWidth >= 920" src="../../assets/index/Group 108@2x.png" alt="">
        <img v-else src="../../assets/index/phone/Group 114@2x.png" alt="">
      </div>
    </div>

    <div class="standard">
      <img v-if="screenWidth >= 920" src="../../assets/index/chennuo.png" alt="">
      <img v-else src="../../assets/index/phone/chennuo.png" alt="">
    </div>
    <div class="standard top bottom">
      <img v-if="screenWidth >= 920" src="../../assets/index/fazhan.png" alt="">
      <img v-else src="../../assets/index/phone/fazhanp.png" alt="">
    </div>
    <Aside></Aside>
  </div>
</template>

<script>
import { toThousands } from '@/common/util'
import { mapState } from 'vuex'
import Aside from '@/components/aside.vue'
export default {
  name: 'home',
  components: {
    Aside
  },
  data() {
    return {
      business: [
        require('../../assets/index/s1.png'),
        require('../../assets/index/s2.png'),
        require('../../assets/index/s3.png'),
        require('../../assets/index/s4.png')
      ],
      businessPhone: [
        require('../../assets/index/phone/sp1.png'),
        require('../../assets/index/phone/sp2.png'),
        require('../../assets/index/phone/sp3.png'),
        require('../../assets/index/phone/sp4.png')
      ],
      // businessHeight: 0,
      // screenWidth: 1920,
      lineHeight: 0,
      num1: 4065863006,
      num2: 32365,
      num3: 33,
      timer1: null,
      timer2: null,
      timer3: null
    }
  },
  computed: {
    ...mapState(['businessHeight', 'screenWidth'])
  },
  watch: {
    '$route.query'() {
      Object.assign(this.$data, this.$options.data())
      this.scrollto(this.$route.query.id)
    }
  },
  mounted() {

  },
  methods: {
    scrollto(id) {
      const dom = document.getElementById(id)
      if (dom) {
        // console.log('滑动', dom.offsetTop)
        window.scrollTo({
          top: dom.offsetTop - 20,
          behavior: "smooth",
        })
        // console.log('滑动结束')
      }
    },
    toThousands: toThousands
  }

}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;

}

.top {
  padding-top: 50px;
}

.bottom {
  padding-bottom: 50px;
}

.banner {
  width: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .nums {
    position: absolute;
    top: 82%;
    margin-left: 13%;
    width: 80%;
    height: 18%;
    display: flex;
    // align-items: center;
  }

  .item {
    height: 100%;
    box-sizing: border-box;
    padding-top: 3%;

    .title {
      font-size: 22px;
      font-weight: 600;

      span {
        color: #2879FF;
        padding-right: 5px;
        font-size: 32px;
      }
    }

    .desc {
      font-size: 13px;
      color: #31396A;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }

  .item1 {
    width: 34.5%;
  }

  .item2 {
    width: 31.3%;
    padding-left: 20px;
  }

  .item3 {
    width: 33.3%;
    padding-left: 20px;
  }
}

.banner_p {
  width: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .nums {
    position: absolute;
    top: 82%;
    margin-left: 4%;
    width: 90%;
    height: 18%;
    display: flex;
    // align-items: center;
  }

  .item {
    height: 100%;
    box-sizing: border-box;
    padding-top: 3%;

    .title {
      font-size: 20px;
      font-weight: 600;

      span {
        color: #2879FF;
        padding-right: 5px;
        font-size: 16px;
      }
    }
  }

  .item1 {
    width: 40.5%;
  }

  .item2 {
    width: 36.3%;
    padding-left: 6.5%;
  }

  .item3 {
    width: 33.3%;
    padding-left: 7%;
  }
}

.diqu {
  width: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
  }
}

.standard {
  width: 100%;
  position: relative;

  img {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
}

.slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 50px;

  .line {
    width: 100%;
  }

  .t {
    width: 90%;
    margin-left: 10%;
    position: relative;
    padding-left: 10px;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 100px;

    &::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #2879FF;
      border-radius: 2px;
    }

  }

  .slide {
    width: 90%;
    margin-left: 10%;
    padding-right: 20px;
    box-sizing: border-box;
    position: relative;
    top: -50px;
    overflow-x: scroll;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      width: 140%;
      height: 1px;
      left: -10%;
      top: 16%;
      background-color: #BCCEF2;
      z-index: -1;
    }

    img {
      display: block;
      width: 120%;
    }
  }
}

@media screen and (min-width: 920px) {
  ::v-deep .el-carousel__indicators--horizontal {
    top: 15%;
    width: 61%;
    bottom: 0;
    height: 17%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }

  ::v-deep .el-carousel__indicator--horizontal {
    width: 25%;
    height: 100%;
  }
}

@media screen and (max-width: 920px) {
  ::v-deep .el-carousel__indicators--horizontal {
    top: 15%;
    width: 90%;
    bottom: 0;
    height: 17%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }

  ::v-deep .el-carousel__indicator--horizontal {
    width: 25%;
    height: 100%;
  }
}


// @media screen and (max-width: 920px) {
//   .slider {
//     .t {
//       padding-left: 10px;
//       font-size: 16px;
//       margin-bottom: 40px;
//     }

//     .slide {
//       top: -25px;
//     }
//   }
// }
@media screen and (max-width: 1400px) {
  .item {
    .title {
      font-size: 16px !important;
    }

    .desc {
      font-size: 12px !important;
    }
  }

  .slider {
    .t {
      padding-left: 10px;
      font-size: 30px;
      margin-bottom: 80px;
    }
  }
}



@media screen and (max-width: 920px) {
  .top {
    padding-top: 30px;
  }

  .bottom {
    padding-bottom: 30px;
  }

  .item {
    .title {
      font-size: 14px !important;
    }

    .desc {
      font-size: 10px !important;
    }
  }

  .slider {
    .t {
      padding-left: 10px;
      font-size: 16px;
    }
  }

  .banner_p {
    width: 100%;
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    .nums {
      position: absolute;
      top: 80.5%;
      margin-left: 4%;
      width: 90%;
      height: 18%;
      display: flex;
      // align-items: center;
    }

    .item {
      .title {
        font-size: 12px !important;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          color: #2879FF;
          padding-right: 5px;
          font-size: 16px !important;
        }
      }
    }


  }
}

@media screen and (max-width: 420px) {
  .top {
    padding-top: 30px;
  }

  .bottom {
    padding-bottom: 30px;
  }

  .item {
    .title {
      font-size: 14px !important;
    }

    .desc {
      font-size: 10px !important;
    }
  }

  .slider {
    .t {
      padding-left: 10px;
      font-size: 16px;
    }
  }

  .banner_p {
    width: 100%;
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    .nums {
      position: absolute;
      top: 80.5%;
      margin-left: 4%;
      width: 90%;
      height: 18%;
      display: flex;
      // align-items: center;
    }

    .item {
      height: 100%;
      box-sizing: border-box;
      padding-top: 3%;
      line-height: 1;

      .title {
        font-size: 8px !important;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          color: #2879FF;
          padding-right: 5px;
          font-size: 12px !important;
        }
      }
    }


  }
}
</style>
