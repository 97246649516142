import axios from 'axios'
import { Message } from 'element-ui'
import { BASE_URL, TIME_OUT } from '../../config.js'

export function request(config) {
    console.log('config', config);
    const service = axios.create({
        baseURL: BASE_URL, //配置公共接口
        timeout: TIME_OUT, //配置请求超时时间
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        
    })

    // 请求拦截器
    service.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['token'] = token;
        }
        console.log('请求拦截',config);
        return config;
    });

    // 响应拦截器
    service.interceptors.response.use(res => {
        console.log('回调完整数据', res)
        console.log('回调data', res.data)
        if (res.status === 200) {
            if(res.data.code != 1){
                Message({
                    duration: 1500,
                    message: res.data.msg,
                    // center: true,
                    type: 'error'
                })
                // 请求出现错误时回调
                return Promise.reject(res.data)
            }
        } else if (res.status === 401) {
            Message({
                duration: 1500,
                message: '未授权，请重新登录',
                // center: true,
                type: 'warning'
            })
        } else {
            Message({
                duration: 1500,
                message: '请求失败',
                // center: true,
                type: 'error'
            })
        }
        return Promise.resolve(res.data)
    }, err => {
        return err
    })
    return service(config)
}
