<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  watch: {
    '$route'(val) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  },
  mounted() {
    let screenWidth = window.innerWidth;
    let businessHeight = 0;
    if (screenWidth >= 920) {
      businessHeight = 600 / 1550 * screenWidth
    } else {
      businessHeight = 600 / 1100 * screenWidth
    }

    // 窗口大小发生改变
    window.onresize = () => {
      console.log(123123123)
      // screenWidth = window.innerWidth
      // if (screenWidth >= 920) {
      //   businessHeight = 600 / 1550 * screenWidth
      // } else {
      //   businessHeight = 600 / 1100 * screenWidth
      // }

      screenWidth = window.innerWidth
      if (screenWidth >= 1920) {
        screenWidth = 1920
      }
      if (screenWidth >= 920) {
        businessHeight = 600 / 1550 * screenWidth
      } else {
        businessHeight = 600 / 1100 * screenWidth

      }
      console.log(screenWidth)
      this.$store.commit('setWidth', screenWidth)
      this.$store.commit('setHeight', businessHeight)
    }

    this.$store.commit('setWidth', screenWidth)
    this.$store.commit('setHeight', businessHeight)
  }
}
</script>
<style lang="scss">
@import url('./assets/css/normalize.css');
@import url('./assets/css/mixin.css');
@import url('./assets/css/px.css');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 1920px;
  // min-width: 920px;
  margin: 0 auto;
}
</style>
