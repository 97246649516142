import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
// 在重写push|replace方法时，先把VueRouter原型对象的push|replace保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 参数1：告诉原来的push | replace方法，你往那里跳转（传递那些参数）
// 参数2：成功回调
// 参数3：失败回调
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => { }, () => { })
    }
}


const About=() => import('../views/about/about.vue')
const Recruit=()=>import('../views/recruit/recruit.vue')
const New=()=>import('../views/news/new.vue')
const NewDetail=()=>import('../views/news/detail.vue')
const Company=()=>import('../views/news/jian1.vue')
// 404
const ThereNo = () => import('../views/404')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '安惠信',
      keepAlive:true  //是否显示头部导航栏和底部
    },
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们',
      keepAlive:true
    },
    component:About
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: {
      title: '人才招聘',
      keepAlive:true
    },
    component:Recruit
  },
  {
    path: '/new',
    name: 'new',
    meta: {
      title: '资讯中心',
      keepAlive:true
    },
    component:New
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    meta: {
      title: '资讯详情',
      keepAlive:true
    },
    component:NewDetail
  },
  {
    path: '/newCompany',
    name: 'newCompany',
    meta: {
      title: '资讯详情',
      keepAlive:true
    },
    component:Company
  },
  {
    path: '/404',
    component: ThereNo,
    meta: {
      title: '404',
      keepAlive:false
    }
  },

  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    // console.log(window.navigator)
    if (flag) {
      console.log('手机端')
     
    } else {
      console.log('pc端')
      
    }
  // console.log('to', to);
  // console.log('from', from);
  // 设置标题
  document.title = to.meta.title
  next()
})
export default router
