<template>
  <div class="aside">
    <div class="aside_con">
      <!-- <div v-popover="popover" class="poster" v-if="screenWidth >= 420">
        <img src="../assets/icons/wechat_icon.png" alt="">
      </div> -->
      <el-popover v-if="screenWidth >= 420" ref="popover" placement="left-start" trigger="click">
        <div>
          <div class="tit" style="text-align: center;font-size: 22px;font-weight: 600;">
            点击测额
          </div>
          <img src="../assets/icons/code.png" alt="二维码">
        </div>
        <div slot="reference" class="poster" v-if="screenWidth >= 420">
          <img src="../assets/icons/tc.png" alt="">
        </div>
      </el-popover>
      <div class="link" v-else>
        <a href="https://wap.xmahx.com">
          <img style="width: 90px;" src="../assets/icons/tc.png" alt="">
        </a>
      </div>

    </div>
    <!-- <div class="top" @click="toTop">
      <img src="../assets/icons/top-icon.png" alt="">
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['screenWidth'])
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// .aside {
//   position: fixed;
//   right: 10px;
//   top: 50%;
//   z-index: 10;
//   transform: translateY(-50%);
// }

.aside_con {
  width: 40px;

}

.poster,
.link {
  position: fixed;
  right: 5px;
  bottom: 8%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 10px 15px 10px 10px;
  border-radius: 5px 0 0 5px;

}

.top {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  z-index: 10;
  border-radius: 50%;

  img {
    width: 50px;
    height: 50px;
  }
}
</style>