/**
 * 时间格式化
 * 根据传入格式,对时间戳进行格式化
 * 
 * @param timestamp 时间戳
 * @param fmt 格式化格式
 */
const timeFormat = (timestamp = null, fmt = 'yyyy-mm-dd') => {
	// 其他更多是格式化有如下:
	// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
	timestamp = parseInt(timestamp);
	// 如果为null,则格式化当前时间
	if (!timestamp) timestamp = Number(new Date());
	// 判断用户输入的时间戳是秒还是毫秒,一般前端js获取的时间戳是毫秒(13位),后端传过来的为秒(10位)
	if (timestamp.toString().length == 10) timestamp *= 1000;
	let date = new Date(timestamp);
	let ret;
	let opt = {
		"y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"h+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"s+": date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		};
	};
	return fmt;
}

/*
* 数字分割
*/
const toThousands = (num = 0) => {
	return num.toString().replace(/\d+/, function(n) {
		 return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
	});
};

/**
 * 全局唯一标识符
 * 用于生成全局唯一标识符uuid
 */
const uuid = () => {
	var s = [];
	var hexDigits = "0123456789abcdef";
	for (var i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4";
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
	s[8] = s[13] = s[18] = s[23] = "-";

	var uuid = s.join("");
	return uuid;
}


/**
 * 防抖函数(可用于防止重复提交)
 * 当持续触发事件时，一定时间段内没有再触发事件，事件处理函数才会执行一次，
 * 如果设定时间到来之前，又触发了事件，就重新开始延时。也就是说当一个用户一直触发这个函数，
 * 且每次触发函数的间隔小于既定时间，那么防抖的情况下只会执行一次。
 *
 * @param fn 执行函数
 * @param delay 间隔时间 默认 500
 * 
 * 案列
 * event:debounce(function(){},delay)
 */
const debounce = (fn, delay = 500) => {
	let timeout = null;
	return function (...args) {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			fn.apply(this, args)
		}, delay);
	};
}

/**
 * 节流函数
 * 当持续触发事件时，保证在一定时间内只调用一次事件处理函数
 * 小于既定值，函数节流会每隔这个时间调用一次
 *
 * @param fn 执行函数
 * @param delay 间隔时间 默认 1000
 * 
 * 案列
 * event:throttle(function(){},delay)
 */
const throttle = (fn, delay = 1000) => {
	let lastCall = 0;
	let throttled = function () {
		const now = new Date().getTime();
		// remaining 不触发下一次函数的剩余时间
		if (now - lastCall < delay && lastCall) return;
		lastCall = now;
		fn.apply(this, arguments)
	}
	return throttled;
}

/**
 * 数组去重
 * 去除数组中重复的数据
 * 
 * @param array 去重数组
 * 
 * 案例
 * dedupe(array)
 */
const dedupe = (array) => {
	return Array.from(new Set(array));
}

/**
	* 去除字符串空格
	*
	* @param str 要处理的字符串
	* @param type 1：所有空格 2：前后空格 3：前空格 4：后空格 默认为 1
	*/
const strTrim = (str, type = 1) => {
	switch (type) {
		case 1:
			return str.replace(/\s+/g, "");
		case 2:
			return str.replace(/(^\s*)|(\s*$)/g, "");
		case 3:
			return str.replace(/(^\s*)/g, "");
		case 4:
			return str.replace(/(\s*$)/g, "");
		default:
			return str;
	}
}

/**
	*隐藏中间4位数的手机号码
	*
	* @param val 要隐藏的手机号
	*/
const hidePhone = (val) => {
	let tel = JSON.stringify(val);
	let reg = /(\d{3})\d{4}(\d{4})/;
	tel = tel.replace(reg, "$1****$2")
	return tel
}

/**
	* 小数点后 四舍五入自动补两个零
	*
	* @param val 要处理的数值
	*/
const amountFillZero = (val) => {
	val = Math.round(parseFloat(val) * 100) / 100;
	let dot = val.toString().split('.');
	if (dot.length === 1) {
		val = val.toString() + '.00';
	} else if (dot.length > 1) {
		if (dot[1].length < 2) {
			val = val.toString() + '0'
		}
	}
	return val
}

export {
	timeFormat,
	uuid,
	debounce,
	throttle,
	dedupe,
	strTrim,
	hidePhone,
	amountFillZero,
	toThousands 
}