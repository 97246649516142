import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 导航选中
    active:'',
    businessHeight:0,
    screenWidth:0
  },
  getters: {
  },
  mutations: {
    setActive(state,payload){
      state.active = payload
    },
    setWidth(state,payload){
      state.screenWidth = payload
    },
    setHeight(state,payload){
      state.businessHeight = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
