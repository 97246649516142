<template>
  <div v-if="$route.meta.keepAlive" class="header">
    <nav>
      <div class="content">
        <img class="logo" src="../assets/logo.png" alt="">
        <!-- <div class='hanbao'>
          <div class="icon nav-icon" :class="aside ? 'open' : ''" @click="openNav($event)">
            <span></span><span></span><span></span>
          </div>
        </div> -->
        <div class="navs">
          <el-dropdown>
            <router-link class="hNav el-dropdown-link" to="/home">首页</router-link>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toPage('/home?id=content', 0, 0)">业务内容</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/home?id=address', 0, 1)">业务分布</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/home?id=standard', 0, 2)">服务标准</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/home?id=process', 0, 3)">服务流程</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/home?id=desc', 0, 4)">公司简介</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <router-link class="hNav el-dropdown-link" to="/recruit">人才招聘</router-link>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toPage('/recruit?id=offi', 1, 0)">行政岗位</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/recruit?id=sale', 1, 1)">销售岗位</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <router-link class="hNav el-dropdown-link" to="/about">关于我们</router-link>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toPage('/about?id=desc', 2, 0)">公司概况</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/about?id=people', 2, 1)">集团创始人</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/about?id=group', 2, 2)">专业团队</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <router-link class="hNav el-dropdown-link last" to="/new">资讯中心</router-link>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toPage('/new?id=company', 3, 0)">公司资讯</el-dropdown-item>
              <el-dropdown-item @click.native="toPage('/new?id=finance', 3, 1)">金融资讯</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="aside">
          <el-menu :default-active="handleAvtive" class="el-menu-vertical-demo" :unique-opened="true"
            background-color="#fff" mode="horizontal" text-color="#181818" active-text-color="#629DFF" @open="handleOpen"
            @select="handleSelect" menu-trigger="click">
            <el-submenu v-for="(item, index) in navs" :index="String(index)" :key="index">
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item v-for="(value, i) in item.children" :index="String(index) + '-' + String(i)">{{
                value.name
              }}</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Header',
  data() {
    return {
      navs: [
        {
          name: '首页',
          link: '/home',
          children: [
            { name: '业务内容', link: '/home', isfirst: false, id: 'content' },
            { name: '业务分布', link: '/home', isfirst: false, id: 'address' },
            { name: '服务标准', link: '/home', isfirst: false, id: 'standard' },
            { name: '服务流程', link: '/home', isfirst: false, id: 'process' },
            { name: '公司简介', link: '/home', isfirst: false, id: 'desc' }
          ]
        },
        {
          name: '人才招聘', link: '/recruit',
          children: [
            { name: '行政岗位', link: '/recruit', isfirst: false, id: 'offi' },
            { name: '销售岗位', link: '/recruit', isfirst: false, id: 'sale' }
          ]
        },
        {
          name: '关于我们', link: '/about',
          children: [
            { name: '公司概况', link: '/about', isfirst: false, id: 'desc' },
            { name: '集团创始人', link: '/about', isfirst: false, id: 'people' },
            { name: '专业团队', link: '/about', isfirst: false, id: 'group' }
          ]
        },
        {
          name: '资讯中心', link: '/new',
          children: [
            { name: '公司资讯', link: '/new', isfirst: false, id: 'company' },
            { name: '金融资讯', link: '/new', isfirst: false, id: 'finance' }
          ]
        }
      ],
      aside: false,
      screenWidth: 0
    }
  },
  computed: {
    handleAvtive() {
      return this.$store.state.active
    }
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth
    // 窗口大小发生改变
    window.onresize = () => {

      this.screenWidth = window.innerWidth
      if (this.screenWidth >= 1920) {
        this.screenWidth = 1920
      }
    }
  },
  methods: {
    openNav(event) {

      if (this.aside) {
        this.aside = false;
      } else {
        this.aside = true;
      }
    },
    handleOpen(key, keyPath) {
      // console.log('open', key, keyPath);
    },
    handleSelect(key, keyPath) {
      // console.log('select', key, keyPath);
      this.$store.commit('setActive', keyPath[1])
      let arr = keyPath[1].split('-')
      let path = this.navs[arr[0]].children[arr[1]]
      // console.log(this.$route)
      this.aside = false
      this.$router.push({ path: path.link, query: { id: path.id } })
    },
    toPage(path, first, second) {
      this.$store.commit('setActive', `${first}-${second}`)
      this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 99;
  box-shadow: 0px 3px 5px #888888;
}

nav {
  width: 100%;
  background-color: #fff;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
    height: 75px;
    position: relative;
  }

  .logo {
    width: 200px;
  }

  .hNav {
    font-weight: bold;
    color: #2c3e50;
    display: inline-block;
    height: 100%;
    margin-right: 20px;
    font-size: 18px;

    &.router-link-exact-active {
      color: #2879FF;
    }
  }

  .hNav.last {
    margin-right: 0;
  }
}

.aside {
  width: 100%;
}

@media screen and (min-width:600px) {
  .hanbao {
    display: none;
  }

  .navs {
    display: block;
  }

  .aside {
    display: none;
  }

  .logo {
    display: block;
  }
}

.el-menu ::v-deep li {
  width: 25%;
  text-align: center;
}

@media screen and (max-width:600px) {
  .header {
    box-shadow: none;
  }

  .content {
    width: 100% !important;
    height: auto !important;
  }

  .hanbao {
    display: block;
  }

  .navs {
    display: none;
  }

  .aside {
    display: block;
  }

  .logo {
    display: none;
  }
}
</style>