//封装post，get请求
import { request } from "./serve.js";
const Request = {
  //  post 封装请求
  post(url, data) {
    // console.log('data', data);
    return request({ url, data, method: 'POST' })
  },
  get(url, params) {
    // console.log('params', params);
    return request({ url, params, method: 'GET' })
  },
}
export default Request